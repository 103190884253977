<template>
    <div class="detailOfTheActivity">
        <div>
            <h1 style="text-align: center;">{{activeInfo.title}}</h1>
            <div style="margin:auto" v-html="activeInfo.content"></div>
        </div>
        <!-- <div class="addbtn">{{$t('button.baoming')}}</div> -->
    </div>
</template>

<script>
import {getActivityInfo} from '@/api/main.js'
export default {
    data() {
        return {
            activeInfo:{}
        }
    },
    mounted() {
        this.getActivityInfo()
    },
    methods: {
        getActivityInfo(){
            getActivityInfo({}).then(res=>{
                if(res.code==0){
                    if(this.isChinese()){
                       this.activeInfo = res.data
                    }else{
                        this.activeInfo = {
                            ...res.data,
                            title:res.data.titleEng,
                            content:res.data.contentEng
                        }
                    }
                }else{
                    this.showMessage();
                }
            })
        }
    }
}
</script>
<style lang="scss">
.detailOfTheActivity {
    width: 1200px;
    margin: auto;
    padding: 48px 0px;
    h1{
        margin-bottom: 30px;
    }
    .addbtn {
        margin-top: 30px;
        height: 50px;
        margin-right: 20px;
        // width: 210px;
        // height: 50px;
        border: 1.5px solid transparent;
        border-radius: 0;
        white-space: nowrap;
        transition: all 0.3s ease;
        border: 2px solid #000;
        text-align: center;
        padding: 15px 65px;
    }

    .addbtn:hover {
        background: #000;
        color: #fff;
        cursor: pointer;
    }
}
</style>